/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import "../cubs.css";

const Cubs = () => {
    const data = useStaticQuery(graphql`
    query {
      cube1: file(name: { eq: "1" }, relativeDirectory: { eq: "cubs"}) {
        childImageSharp {
          fluid(maxWidth: 186) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cube2: file(name: { eq: "2" }, relativeDirectory: { eq: "cubs"}) {
        childImageSharp {
          fluid(maxWidth: 186) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cube3: file(name: { eq: "3" }, relativeDirectory: { eq: "cubs"}) {
        childImageSharp {
          fluid(maxWidth: 186) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cube4: file(name: { eq: "4" }, relativeDirectory: { eq: "cubs"}) {
        childImageSharp {
          fluid(maxWidth: 186) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cube5: file(name: { eq: "5" }, relativeDirectory: { eq: "cubs"}) {
        childImageSharp {
          fluid(maxWidth: 186) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cube6: file(name: { eq: "6" }, relativeDirectory: { eq: "cubs"}) {
        childImageSharp {
          fluid(maxWidth: 186) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cube7: file(name: { eq: "7" }, relativeDirectory: { eq: "cubs"}) {
        childImageSharp {
          fluid(maxWidth: 186) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cube8: file(name: { eq: "8" }, relativeDirectory: { eq: "cubs"}) {
        childImageSharp {
          fluid(maxWidth: 186) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cube9: file(name: { eq: "9" }, relativeDirectory: { eq: "cubs"}) {
        childImageSharp {
          fluid(maxWidth: 186) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cube10: file(name: { eq: "10" }, relativeDirectory: { eq: "cubs"}) {
        childImageSharp {
          fluid(maxWidth: 186) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cube11: file(name: { eq: "11" }, relativeDirectory: { eq: "cubs"}) {
        childImageSharp {
          fluid(maxWidth: 186) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cube12: file(name: { eq: "12" }, relativeDirectory: { eq: "cubs"}) {
        childImageSharp {
          fluid(maxWidth: 186) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

    return (
        <div style={{
            background: "#42210B",
            paddingTop: 48
        }}>
            <div className={"cubs"} sx={{
                margin: `0 auto`,
                maxWidth: 1280,
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                "> div": {
                    marginBottom: 31
                },
            }}>
                <div data-aos="fade-up"><Img fluid={data.cube1.childImageSharp.fluid} alt={""} className={"cubs1"} /></div>
                <div data-aos="fade-up"><Img fluid={data.cube2.childImageSharp.fluid} alt={""} className={"cubs2"} /></div>
                <div data-aos="fade-up"><Img fluid={data.cube3.childImageSharp.fluid} alt={""} className={"cubs3"} /></div>
                <div data-aos="fade-up"><Img fluid={data.cube4.childImageSharp.fluid} alt={""} className={"cubs4"} /></div>
                <div data-aos="fade-up"><Img fluid={data.cube5.childImageSharp.fluid} alt={""} className={"cubs5"} /></div>
                <div data-aos="fade-up"><Img fluid={data.cube6.childImageSharp.fluid} alt={""} className={"cubs6"} /></div>
                <div data-aos="fade-up"><Img fluid={data.cube7.childImageSharp.fluid} alt={""} className={"cubs7"} /></div>
                <div data-aos="fade-up"><Img fluid={data.cube8.childImageSharp.fluid} alt={""} className={"cubs8"} /></div>
                <div data-aos="fade-up"><Img fluid={data.cube9.childImageSharp.fluid} alt={""} className={"cubs9"} /></div>
                <div data-aos="fade-up"><Img fluid={data.cube10.childImageSharp.fluid} alt={""} className={"cubs10"} /></div>
                <div data-aos="fade-up"><Img fluid={data.cube11.childImageSharp.fluid} alt={""} className={"cubs11"} /></div>
                <div data-aos="fade-up"><Img fluid={data.cube12.childImageSharp.fluid} alt={""} className={"cubs12"} /></div>
            </div>
        </div>
    )
}

export default Cubs
