/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types";

const CompanyItem = ({children, name, nameEnglish}) => {
    return (
        <div style={{
            display: "flex",
            paddingBottom: 12,
            marginBottom: 12,
            borderBottom: "1px solid #C4C4C4"
        }}>
            <div style={{
                flex: "0 1 110px",
                minWidth: 110,
                fontSize: 14,
                lineHeight: 2,
                whiteSpace: "nowrap"
            }}>
                <p>{name}</p>
                <p>{nameEnglish}</p>
            </div>
            <div sx={{
                paddingLeft: 60,
                fontSize: 14,
                lineHeight: 2,
                "@media (max-width: 901px)": {
                    paddingLeft: 30,
                }
            }}>{children}</div>
        </div>
    )
}

CompanyItem.propTypes = {
    children: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    nameEnglish: PropTypes.string.isRequired,
}
export default CompanyItem
