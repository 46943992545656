/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const TowaCube = () => {
    const data = useStaticQuery(graphql`
    query {
      towaCube: file(name: { eq: "towa-cube" }) {
        childImageSharp {
          fluid(maxWidth: 5000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      matou: file(name: { eq: "matou" }) {
        childImageSharp {
          fluid(maxWidth: 5000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

    return (
        <div id="towa" sx={{
            paddingTop: 125,
            "@media (max-width: 901px)": {
                paddingTop: 85,
            },
        }}>
            <h2>
                <Img fluid={data.towaCube.childImageSharp.fluid} alt={"Tsutsumu 大切な人へ伝えたい気持ちを包み、贈る。"} style={{margin: "auto"}} />
            </h2>
            <h2 style={{ marginTop: "-31.25%" }}>
                <Img fluid={data.matou.childImageSharp.fluid} alt={"Matou 磨き上げられた、京都の美と感性を纏う"} style={{margin: "auto"}} />
            </h2>
        </div>
    )
}

export default TowaCube
