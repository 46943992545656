/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const Concept = () => {
    const data = useStaticQuery(graphql`
    query {
      conceptTitle: file(name: { eq: "concept-title" }) {
        childImageSharp {
          fluid(maxWidth: 642) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      conceptMatou: file(name: { eq: "concept-matou" }) {
        childImageSharp {
          fluid(maxWidth: 464) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      conceptTsutsumu: file(name: { eq: "concept-tsutsumu" }) {
        childImageSharp {
          fluid(maxWidth: 464) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      conceptStory: file(name: { eq: "concept-story" }) {
        childImageSharp {
          fluid(maxWidth: 446) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

    return (
        <div id="concept" style={{
            background: "#291700",
            paddingTop: 51,
            paddingBottom: 70,
        }}>
            <div data-aos="fade-up" sx={{
                maxWidth: 1106,
                margin: "auto",
                padding: `0 1.0875rem 0`,
            }}>
            <h2 style={{
                marginBottom: 78
            }}>
                <Img fluid={data.conceptTitle.childImageSharp.fluid} alt={"TOWA Cube CONCEPT 大切想いを届けたい人に贈るための京文化の彩りとフレーバーが調和したちょっと贅沢なチョコレートです。"} style={{margin: "auto"}} />
            </h2>
            <div data-aos="fade-up" sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 91,
                "> div": {
                    width: "50%"
                },
                "@media (max-width: 901px)": {
                    flexDirection: "column",
                    "> div": {
                        width: "100%"
                    },
                    "> div:first-child": {
                        marginBottom: 91,
                    },
                }
            }}>
                <div>
                    <Img fluid={data.conceptMatou.childImageSharp.fluid} alt={"Matou 磨き上げられた京都の美と感性を纏う"} style={{margin: "auto"}} />
                    <div sx={{
                        padding: "0 30px",
                        margin: "auto",
                        marginTop: 28,
                        color: "#fff",
                        fontSize: 14,
                        lineHeight: "24px",
                        maxWidth: 464,
                        "@media (max-width: 901px)": {
                            padding: "0",
                        }

                    }}>
                        <p>移りゆく四季の中で、永きに渡り大切に磨かれてきた日本・京都の文化と伝統を守りながら、新しい価値を創造し、世界中のより多くの人々にその素晴らしさを伝えることを使命とし、TOWAはその一つひとつに、京都で生まれた美と感性を纏っています。</p>
                    </div>
                </div>
                <div>
                    <Img fluid={data.conceptTsutsumu.childImageSharp.fluid} alt={"Tsutsumu 大切な人へ伝えたい気持ちを包み、贈る"} style={{margin: "auto"}} />
                    <div sx={{
                        padding: "0 30px",
                        margin: "auto",
                        marginTop: 28,
                        color: "#fff",
                        fontSize: 14,
                        lineHeight: "24px",
                        maxWidth: 464,
                        "@media (max-width: 901px)": {
                            padding: "0",
                        }
                    }}>
                        <p>TOWAのチョコレートには、それぞれに伝えたい想いが込められています。慶次や祝い事、日常の中で気持ちを伝えたい大切な人へ、想いを組み合わせて包み、贈る。日本人が持つ、相手を思いやる気持ちを大切にしています。</p>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up">
                <Img fluid={data.conceptStory.childImageSharp.fluid} alt={"Brand Story 着物とチョコレートのマリアージュが新たな文化の継承へとつながる。"} style={{margin: "auto"}} />
                <div style={{
                    margin: "auto",
                    marginTop: 28,
                    color: "#fff",
                    fontSize: 14,
                    lineHeight: "24px",
                    maxWidth: 684,
                }}>
                    <p>固有の伝統を守りながら、新たな価値を見出すことで次の世代に伝える、KIMONO BRAND「和風音」の革新的な着物デザインにインスピレーションを受け、スイーツブランド「TOWA」は生まれました。四季折々の、京都の情景の中で育まれてきた繊細な色覚と感性、大切な想いを紡いできた伝統の文様と、伝統と革新が創出した美しい着物柄、日本の味覚と、チョコレートとの出会い。</p>
                    <p>日本・京都文化の彩りと風味の贅沢な調和。TOWAは、大切な想いを届けたい人に贈るための、ちょっと贅沢なチョコレートです。</p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Concept
