import React, {useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TowaCube from "../components/top/towa-cube";
import Concept from "../components/top/concept";
import Creator from "../components/top/creator";
import Company from "../components/top/company";
import Map from "../components/top/map";
import ReturnToTop from "../components/return-to-top";
import Cubs from "../components/top/cubs";
import Capucho from "../components/top/capucho";
import News from "../components/top/news";
import AOS from 'aos';
import 'aos/dist/aos.css';

const IndexPage = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div style={{
            minHeight: "100vh",
            background: "#291700"
        }}>
            <Layout isTop={true}>
                <SEO title="Home" />

                <TowaCube />

                <Cubs />

                <Concept />

                <News />

                <Capucho />

                <Creator />

                <Company />

                <Map />

                <ReturnToTop />
            </Layout>
        </div>
    )
}

export default IndexPage
