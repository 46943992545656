/** @jsx jsx */
import { jsx } from "theme-ui"
import React, {useEffect, useState} from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import NewsItem from "./news-item";
import * as firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment-timezone";

const FIREBASE_API_KEY = process.env.GATSBY_FIREBASE_API_KEY;
const FIREBASE_AUTH_DOMAIN = process.env.GATSBY_FIREBASE_AUTH_DOMAIN;
const FIREBASE_DATABASE_URL = process.env.GATSBY_FIREBASE_DATABASE_URL;
const FIREBASE_PROJECT_ID = process.env.GATSBY_FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET = process.env.GATSBY_FIREBASE_STORAGE_BUCKET;
const FIREBASE_MESSAGING_SENDER_ID = process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_APP_ID = process.env.GATSBY_FIREBASE_APP_ID;
const FIREBASE_APP_MEASUREMENT_ID = process.env.GATSBY_FIREBASE_APP_MEASUREMENT_ID;

const News = () => {
    const data = useStaticQuery(graphql`
    query {
      logo: file(name: { eq: "news-topics" }) {
        childImageSharp {
          fixed(width: 221) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

    const [news, setNews] = useState([]);

    useEffect(() => {
        const firebaseConfig = {
            apiKey: FIREBASE_API_KEY,
            authDomain: FIREBASE_AUTH_DOMAIN,
            databaseURL: FIREBASE_DATABASE_URL,
            projectId: FIREBASE_PROJECT_ID,
            storageBucket: FIREBASE_STORAGE_BUCKET,
            messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
            appId: FIREBASE_APP_ID,
            measurementId: FIREBASE_APP_MEASUREMENT_ID,
        };

        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        }

        const db = firebase.firestore();
        db.collection("news").orderBy("date", "desc").get().then((snapshot) => {
            const items = [];
            snapshot.docs.forEach(doc => {
                const data = doc.data();
                let date = moment().tz("Asia/Tokyo").format("YYYY.MM.DD");
                try {
                    date = moment(doc.data().date.toDate()).tz("Asia/Tokyo").format("YYYY.MM.DD");
                } catch (e) {
                    console.log(e)
                }
                data.date = date
                items.push(data);
            });
            setNews(items);
        });
    }, []);

    return (
        <div data-aos="fade-up" sx={{
            paddingTop: 30,
            background: "#E6E6E6",
            "@media (max-width: 901px)": {
                paddingBottom: 21,
            },
            display: `${!news || news.length === 0? "none" : "block"}`
        }}>
            <div style={{
                maxWidth: 960,
                margin: "auto",
                padding: `0 1.0875rem 0`,
            }}>
                <h2 style={{
                    marginBottom: 27
                }}>
                    <Img fixed={data.logo.childImageSharp.fixed} alt={"NEWS & TOPICS"} />
                </h2>

                {news.map((newsData, newsIndex) => (
                    <NewsItem key={`news^${newsIndex}`} date={newsData.date} link={newsData.link || ""}>
                        {!!newsData.title_ja === true && <p>{newsData.title_ja}</p>}
                        {!!newsData.title_en === true && <p>{newsData.title_en}</p>}
                    </NewsItem>
                ))}
            </div>
        </div>
    )
}

export default News
