/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const Capucho = () => {
    const data = useStaticQuery(graphql`
    query {
      capucho: file(name: { eq: "capucho" }) {
        childImageSharp {
          fluid(maxWidth: 5000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      capucho1: file(name: { eq: "1" }, relativeDirectory: { eq: "capucho"}) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      capucho2: file(name: { eq: "2" }, relativeDirectory: { eq: "capucho"}) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      capucho3: file(name: { eq: "3" }, relativeDirectory: { eq: "capucho"}) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      capucho4: file(name: { eq: "4" }, relativeDirectory: { eq: "capucho"}) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      capucho5: file(name: { eq: "5" }, relativeDirectory: { eq: "capucho"}) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      capucho6: file(name: { eq: "6" }, relativeDirectory: { eq: "capucho"}) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

    return (
        <div>
            <h2 data-aos="fade-up">
                <Img fluid={data.capucho.childImageSharp.fluid} alt={"Capucho かぷっと食べられる和のチョコレート。京のあいのもん「かぷちょ」"} style={{margin: "auto"}} />
            </h2>
            <div data-aos="fade-up" sx={{
                display: "flex",
                flexWrap: "wrap",
                "> div": {
                    flex: "0 0 33.333%"
                },
                "@media (max-width: 901px)": {
                    "> div": {
                        flex: "0 0 50%"
                    },
                }
            }}>
                <Img fluid={data.capucho1.childImageSharp.fluid} alt={"aa"} />
                <Img fluid={data.capucho2.childImageSharp.fluid} alt={""} />
                <Img fluid={data.capucho3.childImageSharp.fluid} alt={""} />
                <Img fluid={data.capucho4.childImageSharp.fluid} alt={""} />
                <Img fluid={data.capucho5.childImageSharp.fluid} alt={""} />
                <Img fluid={data.capucho6.childImageSharp.fluid} alt={""} />
            </div>
        </div>
    )
}

export default Capucho
