/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types";

const NewsItem = ({children, date, link}) => {
    return (
        <div sx={{
            display: "flex",
            paddingBottom: 41,
            "@media (max-width: 901px)": {
                flexDirection: "column",
                marginBottom: 0,
                paddingBottom: 21,
            }
        }}>
            <div style={{
                fontSize: 14,
                fontWeight: "bold",
                whiteSpace: "nowrap",
                marginRight: 31,
                color: "#808080"
            }}>{date}</div>
            <div style={{
                fontSize: 14,
                lineHeight: "24px",
                color: "#42210B"
            }}>
                {!link? children : <a href={link} target={"_blank"} rel={"noopener noreferrer"} sx={{
                    color: "#42210B",
                    display: "block",
                }}>
                    {children}
                </a>}
            </div>
        </div>
    )
}

NewsItem.propTypes = {
    children: PropTypes.node.isRequired,
    date: PropTypes.string.isRequired,
    link: PropTypes.string,
}

export default NewsItem
