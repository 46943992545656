/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const Creator = () => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          towaInstagram
        }
      }
      creator: file(name: { eq: "creator" }) {
        childImageSharp {
          fluid(maxWidth: 176) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      name: file(name: { eq: "shuhei-ogawa-name" }) {
        childImageSharp {
          fluid(maxWidth: 204) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      photo: file(name: { eq: "shuhei-ogawa-photo" }) {
        childImageSharp {
          fluid(maxWidth: 240) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      towaInstagramButton: file(name: { eq: "towa-instagram-button" }) {
        childImageSharp {
          fluid(maxWidth: 369) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

    return (
        <div data-aos="fade-up" style={{
            backgroundColor: "#291700",
            paddingTop: 67
        }}>
            <div id={"creator"} style={{
                maxWidth: 960,
                padding: `0 1.0875rem 0`,
                margin: "auto"
            }}>
                <div style={{ marginBottom: 50 }}>
                    <Img fluid={data.creator.childImageSharp.fluid} alt={"creator"} />
                </div>
                <div sx={{
                    display: "flex",
                    "@media (max-width: 901px)": {
                        flexDirection: "column"
                    },
                    paddingBottom: 44,
                    marginBottom: 55,
                    borderBottom: "1px solid #FFFFFF"
                }}>
                    <div sx={{
                        flex: "0 0 240px",
                        marginRight: 56,
                        "@media (max-width: 901px)": {
                            marginRight: 0,
                            marginBottom: 32
                        }
                    }}>
                        <Img fluid={data.photo.childImageSharp.fluid} alt={"和風音代表 小川 修平"} />
                    </div>
                    <div>
                        <div style={{marginBottom: 26}}>
                            <Img fluid={data.name.childImageSharp.fluid} alt={"KIMONO Artist Shuhei Ogawa"} />
                        </div>
                        <div style={{color: "#fff", fontSize: 14, lineHeight: 2}}>
                            <div style={{marginBottom: 16}}>
                                <p>着物の文化を着物に触れる機会のない方達にチョコレートを通して感じて・触れて頂きたいという想い。</p>
                                <p>着物の柄には様々な意味がある、柄の持つ意味を知る事で、和に興味を抱いてもらえたり、日常にある文様・柄などを見て楽しんで欲しい。</p>
                            </div>
                            <div style={{marginBottom: 16}}>
                                <p>相手の事を考え、思い遣る。</p>
                            </div>
                            <div>
                                <p>伝統・風習など日本の素晴らしい事を伝えていければ。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    paddingBottom: 53,
                    textAlign: "center"
                }}>
                    <a href={data.site.siteMetadata.towaInstagram} target={"_blank"} rel={"noopener noreferrer"} sx={{
                        display: "block",
                        width: "100%",
                        margin: "auto",
                        "@media (max-width: 901px)": {
                            maxWidth: 300,
                        }
                    }}>
                        <Img fluid={data.towaInstagramButton.childImageSharp.fluid} style={{
                            margin: "auto"
                        }} alt={"和風音 Instagram"} />
                    </a>

                    <div id="company" />
                </div>
            </div>
        </div>
    )
}

export default Creator
